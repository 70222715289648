import { graphql } from "gatsby";
import { first, get, isNil } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import Hero from "../../components/hero";
import Intro from "../../components/intro/product";
import Reviews from "../../components/reviews";
import {
  Image,
  TideItemForEvenementLocatie,
  TideItemForHeroComponent,
  TideItemForIntroComponent,
  TideItemForOtherPackagesComponent,
  TideItemForTestimonialsComponent,
  TideItemForToernooi,
  TideItemForUspFolder,
  TideItemForWebsite,
  TideItemForWedstrijd,
  TideItemForWildcardEventPage,
} from "../../types";
import { findSeasonGameById, findTournamentByTagId, parse } from "../component";
import { Breadcrumb } from "../../components/breadcrumb";
import PackageContext, { PackageContextType } from "../../contexts/package-provider";
import ProductCardGrid from "../../components/cardgrid/product-cardgrid";
import { CreateProductCardItems } from "../package-utils";
import translate from "../translate";
import translations from "./translations.json";
import { ComponentContext, ComponentContextType } from "../../contexts/component-provider";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";

interface MapEventPageComponentsProps {
  website: TideItemForWebsite;
  page: TideItemForWedstrijd;
  breadcrumbs: Breadcrumb[];
  wildcardEventPage: TideItemForWildcardEventPage;
  venues: TideItemForEvenementLocatie[];
  competitionTagIds: number[];
  flightsIncluded: boolean;
  usps: TideItemForUspFolder;
}

const MapEventPageComponents: React.FC<MapEventPageComponentsProps> = ({
  website,
  page,
  breadcrumbs,
  wildcardEventPage,
  venues,
  competitionTagIds,
  usps,
}) => {
  const { packageDetail, teamPackages, teamPackagesLoading, otherTournamentPackages, otherTournamentPackagesLoading, setOtherTournamentTagId } =
    useContext<PackageContextType>(PackageContext);
  const { language, affiliate } = useContext<GlobalContextType>(GlobalContext);
  const { teams, tournaments, games } = useContext<ComponentContextType>(ComponentContext);
  const [eventLocation, setEventLocation] = useState<string>("");
  const [tournament, setTournament] = useState<TideItemForToernooi>();

  useEffect(() => {
    if (packageDetail) {
      // the tourcode is a concatination of eventId and ticketId for imported events
      const eventId = first(packageDetail.allotment.tourCode.split("|"));
      const seasonGame = findSeasonGameById(page, eventId);
      if (seasonGame) {
        setEventLocation(
          venues.find((v) => v.content?.general?.id === seasonGame.content?.general?.venueId)?.content?.address?.location?.searchValue ?? ""
        );
      }

      // fetch other matches for the same competition
      const competitionTagId = packageDetail.allotment.tagIds.find((ati) => competitionTagIds.includes(ati));
      if (competitionTagId) {
        setOtherTournamentTagId(competitionTagId);
        setTournament(findTournamentByTagId(tournaments, competitionTagId));
      }
    }
  }, [packageDetail]);

  const homeTeam = teams.find((t) => t.content?.general?.id === page.content?.general?.homeTeamId);
  const componentRenderers = {
    TideItemForTestimonialsComponent: (componentItem: TideItemForTestimonialsComponent) => {
      return (
        <Reviews
          key={componentItem.id}
          testimonials={componentItem}
          specificReviewName={
            page.content?.kiyoh?.name
              ? `review ${page.content.kiyoh.name}`
              : homeTeam?.content?.kiyoh?.name
              ? `review ${homeTeam.content.kiyoh.name}`
              : undefined
          }
        />
      );
    },
    TideItemForHeroComponent: (componentItem: TideItemForHeroComponent) => {
      return (
        <Hero
          key={componentItem.id}
          image={parse(page.content?.general?.hero?.url ? page.content.general.hero : homeTeam?.content?.general?.hero)}
          breadcrumbs={breadcrumbs}
          showQsm={parse(componentItem.content?.general?.showQsm)}
          qsmSlimVersion={true}
          extraClass="header--list-page"
        />
      );
    },
    TideItemForIntroComponent: (componentItem: TideItemForIntroComponent) => {
      const introImages = parse(page.content?.general?.introImages) as Image[];
      return (
        <Intro
          key={componentItem.id}
          text={parse(page.content?.general?.intro)}
          title={parse(page.content?.general?.title)}
          images={introImages}
          eventLocation={eventLocation}
          tournamentTitle={parse(tournament?.content?.general?.title)}
          website={parse(website)}
        />
      );
    },
    TideItemForOtherPackagesComponent: (componentItem: TideItemForOtherPackagesComponent) => {
      const type = first(componentItem.content?.general?.type);
      switch (type) {
        case "Club":
          const teamCardItems =
            CreateProductCardItems(teamPackages, translate(translations, language, "SHOW_TRIP"), true, teams, tournaments, games, affiliate) ?? [];
          return (
            teamCardItems &&
            teamCardItems.length > 0 && (
              <ProductCardGrid
                cardGridTitle={translate(translations, language, "OTHER_PACKAGES_FOR_CLUB_TITLE", { club: homeTeam?.name })}
                cardGridItems={teamCardItems}
                loading={teamPackagesLoading}
                key={componentItem.id}
              ></ProductCardGrid>
            )
          );
        case "Tournament":
          const tournamentCardItems =
            CreateProductCardItems(
              otherTournamentPackages,
              translate(translations, language, "SHOW_TRIP"),
              true,
              teams,
              tournaments,
              games,
              affiliate
            ) ?? [];
          return (
            tournamentCardItems &&
            tournamentCardItems.length > 0 && (
              <ProductCardGrid
                cardGridTitle={translate(translations, language, "OTHER_PACKAGES_FOR_TOURNAMENT_TITLE", {
                  tournament: tournament?.name ?? translate(translations, language, "THIS_TOURNAMENT"),
                })}
                cardGridItems={tournamentCardItems}
                loading={otherTournamentPackagesLoading}
                key={componentItem.id}
              ></ProductCardGrid>
            )
          );
        default:
          return <></>;
      }
    },
  };

  return (
    <>
      {wildcardEventPage?.childItems?.map((tideItem) => {
        const typeName = get(tideItem, "__typename");
        const renderer = get(componentRenderers, typeName);

        if (!isNil(renderer)) {
          return renderer(tideItem);
        }

        return null;
      })}
    </>
  );
};

export default MapEventPageComponents;

export const query = graphql`
  fragment TideItemForTestimonialsFragment on TideItemForTestimonialsComponent {
    __typename
    id
    name
    content {
      general {
        description
        reviewLinkText
      }
    }
  }

  fragment TideItemForOtherPackagesComponentFragment on TideItemForOtherPackagesComponent {
    __typename
    id
    content {
      general {
        type
      }
    }
  }

  fragment TideItemForOtherEventsComponentFragment on TideItemForOtherEventsComponent {
    __typename
    id
  }
`;
