import { graphql, PageProps } from "gatsby";
import { isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Breadcrumb } from "../../components/breadcrumb";
import Footer from "../../components/footer";
import Layout from "../../components/layout";
import Navbar from "../../components/navbar";
import NotificationBar from "../../components/notification-bar";
import { PackageProvider } from "../../contexts/package-provider";
import Seo from "../../components/seo";
import {
  TideItemForEvenementLocatieConnection,
  TideItemForHotelConnection,
  TideItemForSeizoenWedstrijd,
  TideItemForUspFolder,
  TideItemForWebsite,
  TideItemForWedstrijd,
  TideItemForWildcardEventPage,
  TideTagConnection,
} from "../../types";
import { getChildItemsWithTypename, parse } from "../../utils/component";
import MapEventPageComponents from "../../utils/mappers/map-event-page-components";
import GlobalContext from "../../contexts/global-provider";
import { ComponentContext, ComponentContextType } from "../../contexts/component-provider";
import { HotelProvider } from "../../contexts/hotel-provider";

export interface EventPageTemplateData {
  website: TideItemForWebsite;
  page: TideItemForWedstrijd;
  wildcardEventPage: TideItemForWildcardEventPage;
  venues: TideItemForEvenementLocatieConnection;
  cmsHotels: TideItemForHotelConnection;
  competitionTags: TideTagConnection;
  usps: TideItemForUspFolder;
}

interface EventPageTemplateContext {
  language: string;
  breadcrumbs: Breadcrumb[];
  tournamentIds: string[];
}

const EventPageTemplate: React.FC<PageProps<EventPageTemplateData, EventPageTemplateContext>> = ({
  data,
  pageContext: { language, breadcrumbs, tournamentIds },
}) => {
  const globalContext = useContext(GlobalContext);
  const [eventIds, setEventIds] = useState<string[]>([]);
  const [teamEventIds, setTeamEventIds] = useState<string[]>([]);
  const [otherTournamentTagId, setOtherTournamentTagId] = useState<number>();
  const { games, notificationBar, primaryNavigation, setGlobalData } = useContext<ComponentContextType>(ComponentContext);

  let flightsIncluded = true;
  let allotmentTourCode = undefined;
  if (typeof window !== "undefined") {
    const flightsParam = new URLSearchParams(window.location.search).get("flights");
    flightsIncluded = flightsParam ? flightsParam === "true" : true;
    const tourCodeParam = new URLSearchParams(window.location.search).get("tourcode");
    allotmentTourCode = tourCodeParam ?? undefined;
  }

  useEffect(() => {
    (async () => {
      if (globalContext && globalContext.language !== language) {
        globalContext.setLanguage(language);
      }
      if (!games || games.length == 0) {
        await setGlobalData();
      }

      setEventIds(
        (getChildItemsWithTypename("TideItemForSeizoenWedstrijd", data.page) as TideItemForSeizoenWedstrijd[])
          .filter((es) => es.content?.general?.eventStartDate && new Date(es.content.general.eventStartDate) >= new Date())
          .map((es) => es.content?.general?.id) as string[]
      );

      if (typeof window !== "undefined") {
        const affiliateId = new URLSearchParams(window.location.search).get("vrp");
        if (affiliateId) {
          globalContext.setAffiliate(affiliateId);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (data.page.content?.general?.homeTeamId && games) {
      const filteredGames = games.filter(
        (g) =>
          g.content?.general?.homeTeamId === data.page.content?.general?.homeTeamId ||
          g.content?.general?.awayTeamId === data.page.content?.general?.homeTeamId
      );
      setTeamEventIds(
        filteredGames
          .filter((g) => g.id !== data.page.id)
          .flatMap(
            (game) =>
              (getChildItemsWithTypename("TideItemForSeizoenWedstrijd", game) as TideItemForSeizoenWedstrijd[])
                .filter((es) => es.content?.general?.eventStartDate && new Date(es.content.general.eventStartDate) >= new Date())
                .map((es) => es.content?.general?.id) as string[]
          )
      );
    }
  }, [games]);

  return (
    <>
      {primaryNavigation && (
        <Layout>
          <Seo
            description={parse(data.page.content?.seo?.seoDescription)}
            keywords={parse(data.page.content?.seo?.seoKeywords)}
            title={parse(data.page.content?.seo?.seoTitle)}
            website={parse(data.website)}
            breadcrumbs={parse(breadcrumbs)}
          />
          {!isEmpty(notificationBar?.content?.general?.notification) && (
            <NotificationBar
              text={notificationBar?.content?.general?.notification ?? ""}
              icon={parse(notificationBar?.content?.general?.iconFontAwesome)}
            />
          )}
          <Navbar website={parse(data.website)} />
          {globalContext.language === language && games.length > 0 && (
            <HotelProvider hotels={data.cmsHotels.nodes}>
              <PackageProvider
                allotmentTourCode={allotmentTourCode}
                eventIds={eventIds}
                flightsIncluded={flightsIncluded}
                teamEventIds={teamEventIds}
                otherTournamentTagId={otherTournamentTagId}
                eventPage={true}
              >
                <MapEventPageComponents
                  website={data.website}
                  page={data.page}
                  breadcrumbs={breadcrumbs}
                  wildcardEventPage={data.wildcardEventPage}
                  venues={data.venues.nodes}
                  competitionTagIds={data.competitionTags.nodes.map((n) => n.tideId)}
                  flightsIncluded={flightsIncluded}
                  usps={data.usps}
                />
              </PackageProvider>
            </HotelProvider>
          )}
          <Footer name={parse(data.website.name)} logo={parse(data.website.content?.general?.logo)} />
        </Layout>
      )}
    </>
  );
};

export default EventPageTemplate;

export const query = graphql`
  query EventPageQuery($pageId: String, $websiteId: String, $language: String) {
    wildcardEventPage: tideItemForWildcardEventPage(parentItem: { id: { eq: $websiteId } }) {
      __typename
      id
      name
      childItems {
        ...TideItemForHeroComponentFragment
        ...TideItemForTestimonialsFragment
        ...TideItemForIntroComponentFragment
        ...TideItemForOtherPackagesComponentFragment
        ...TideItemForOtherEventsComponentFragment
      }
    }
    website: tideItemForWebsite(id: { eq: $websiteId }) {
      name
      content {
        general {
          phone
          helptext
          linkSgr
          linkThuiswinkelWaarborg
          linkTelephone
          logo {
            altText
            url
            title
          }
          linkLogo
        }
      }
    }
    page: tideItemForWedstrijd(id: { eq: $pageId }) {
      id
      language
      content {
        general {
          intro
          introImages {
            altText
            title
            url
          }
          hero {
            url
            title
            altText
          }
          title
          homeTeamId
        }
        seo {
          seoTitle
          seoDescription
          seoKeywords
        }
        kiyoh {
          name
        }
      }
      childItems {
        ... on TideItemForSeizoenWedstrijd {
          __typename
          content {
            general {
              id
              tournamentId
              venueId
              eventStartDate
            }
          }
        }
      }
    }
    venues: allTideItemForEvenementLocatie(filter: { language: { eq: $language } }) {
      nodes {
        name
        language
        content {
          general {
            id
          }
          address {
            location {
              searchValue
            }
          }
        }
      }
    }
    cmsHotels: allTideItemForHotel(filter: { language: { eq: $language } }) {
      nodes {
        content {
          general {
            stars
            entity {
              code
            }
          }
        }
      }
    }
    competitionTags: allTideTag(filter: { tagGroup: { name: { eq: "Competitie" } } }) {
      nodes {
        tideId
      }
    }
    usps: tideItemForUspFolder(parentItem: { id: { eq: $websiteId } }) {
      name
      childItems {
        ... on TideItemForUspItem {
          __typename
          id
          name
          content {
            general {
              fai
              text
              title
            }
          }
        }
      }
    }
  }
`;
